import jump from 'jump.js'
import Swiper from 'swiper'

const init = (evt: Event): void => {
    const button = document.querySelector('.blk-SessionPanel_Button--link')
    if (button) button.addEventListener('click', onClickSessionPanel, false)

    const $slider = document.querySelector('.p-Slider_Container');
    if ($slider) new Swiper(($slider as HTMLDivElement), {
        loop: true,
        navigation: {
            nextEl: '.p-Slider .swiper-button-next',
            prevEl: '.p-Slider .swiper-button-prev',
        }
    })
}

window.addEventListener(
    'DOMContentLoaded',
    init,
    false
)

const onClickSessionPanel = (evt: Event): void => {
    const target = document.querySelector('.p-BriefingList');
    if (target) {
        const nav = document.querySelector(".l-Navigation_Inner");
        const spNav = document.querySelector(".l-SpMenu");
        const navHeight = nav?.clientHeight || 0;
        const spNavHeight = spNav?.clientHeight || 0;
        const offset = spNavHeight > 0 ? spNavHeight : navHeight;
        jump(target, { offset: offset ? offset * -1 : 0 })
    }
}